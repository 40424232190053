import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
} from '@floating-ui/react'
import type { Placement } from '@floating-ui/react'
import classNames from 'classnames'
import { useState, useMemo, forwardRef, useContext, createContext, isValidElement, cloneElement } from 'react'

interface TooltipOptions {
  initialOpen?: boolean
  placement?: Placement
  open?: boolean
  onMouseOver?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  className?: string
}

function useTooltip({ initialOpen = false, placement = `right`, open: controlledOpen }: TooltipOptions = {}) {
  const [open, setOpen] = useState(initialOpen)

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        crossAxis: placement.includes(`-`),
        fallbackAxisSideDirection: `start`,
        padding: 5,
      }),
      shift({ padding: 5 }),
    ],
  })

  const context = data.context

  const hover = useHover(context, {
    move: false,
    enabled: controlledOpen == null,
  })

  const interactions = useInteractions([hover])

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data],
  )
}

type ContextType = ReturnType<typeof useTooltip> | null

const TooltipContext = createContext<ContextType>(null)

const useTooltipContext = () => {
  const context = useContext(TooltipContext)

  if (context == null) {
    throw new Error(`Tooltip components must be wrapped in <Tooltip />`)
  }

  return context
}

export function Tooltip({
  children,
  message,
  ...options
}: { children: React.ReactNode; message?: React.ReactNode } & TooltipOptions) {
  const tooltip = useTooltip(options)
  return (
    <TooltipContext.Provider value={tooltip}>
      <TooltipTrigger asChild={true} onMouseOver={options.onMouseOver}>
        <span x-component-name="Tooltip" className={classNames(`cursor-default`, options.className)}>
          {children}
        </span>
      </TooltipTrigger>
      <TooltipContent className="tooltip-wrapper z-50">{message}</TooltipContent>
    </TooltipContext.Provider>
  )
}
// This is the trigger element that will be used to open the tooltip. All props will be forwarded to this element. (See cloneElement inside first return statement and span inside second return statement).
// If `asChild` is true, the user can pass any element as the anchor, otherwise a span will be used.
const TooltipTrigger = forwardRef<HTMLElement, React.HTMLProps<HTMLElement> & { asChild?: boolean }>(
  function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
    const context = useTooltipContext()
    const childrenRef = (children as any)?.ref
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

    // `asChild` allows the user to pass any element as the anchor
    if (asChild && isValidElement(children)) {
      return cloneElement(
        children,
        context.getReferenceProps({
          ref,
          ...props,
          ...children.props,
          style: { ...children.props.style, pointerEvents: `auto` },
          'data-state': context.open ? `open` : `closed`,
        }),
      )
    }

    return (
      <span
        ref={ref}
        className="pointer-events-auto"
        // The user can style the trigger based on the state
        data-state={context.open ? `open` : `closed`}
        {...context.getReferenceProps(props)}
      >
        {children}
      </span>
    )
  },
)
// This is the content that will be shown when the tooltip is open. All props will be forwarded to this element. (See span inside return statement)
const TooltipContent = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(function TooltipContent(
  { style, children, ...props },
  propRef,
) {
  const context = useTooltipContext()
  const ref = useMergeRefs([context.refs.setFloating, propRef])

  if (!context.open || !children) return null

  return (
    <FloatingPortal>
      <span
        ref={ref}
        style={{
          ...context.floatingStyles,
          ...style,
        }}
        {...context.getFloatingProps(props)}
      >
        {children}
      </span>
    </FloatingPortal>
  )
})
